define('incus-vastaanota/utils/list-utils', ['exports', 'ember', 'incus-vastaanota/models/invoice', 'incus-vastaanota/models/traffic', 'incus-vastaanota/models/receivedtraffic'], function (exports, _ember, _incusVastaanotaModelsInvoice, _incusVastaanotaModelsTraffic, _incusVastaanotaModelsReceivedtraffic) {
  exports['default'] = {
    sumOver: function sumOver(invoiceArray, fromDays, toDays, paid) {
      // Epsilon is to help with float rounding errors
      var now = moment();
      now.millisecond(0);
      now.second(0);
      now.minute(0);
      now.hour(0);

      var from = null;
      var to = null;
      if (fromDays) {
        from = now.clone();
        from.add(fromDays, 'days');
      }
      if (toDays) {
        to = now.clone();
        to.add(toDays, 'days');
      }

      var sum = 0;
      invoiceArray.forEach(function (invoice) {
        var d = moment(invoice.get("dueDate"), 'YYYYMMDD');
        if (from && d.isBefore(from)) {
          return;
        }
        if (to && d.isAfter(to)) {
          return;
        }
        var amountToPay = parseFloat(invoice.get("amountToPay"));

        if (!amountToPay) {
          return;
        }

        if (paid && invoice.get("paymentStatus") === 'PAID') {
          sum += amountToPay;
        } else {
          if (paid) {
            sum += parseFloat(invoice.get("totalPaidSum"));
          }
        }
        if (!paid && invoice.get("paymentStatus") === 'NOTPAID') {
          //sum += amountToPay;
          sum += parseFloat(invoice.get("unpaidSum"));
        }
      });

      return sum;
    },

    // Returns processed data and from date for new api call.
    processSearchReceivedArchiveData: function processSearchReceivedArchiveData(data, apixclient, userData) {
      var _this = this;

      var invoiceList = [];
      var queryLimitExceeded = false;
      // The query only returns 100 elements. If more present, first element
      // will be 'too long' warning.
      if (data.data[0] && data.data[0].Warning == "Search return list too long") {
        queryLimitExceeded = true;
        data.data.shift();
      }
      data.data.forEach(function (entry) {
        //Ember.Logger.log(entry);
        var id = entry.StorageID;

        var invoice = _incusVastaanotaModelsInvoice['default'].create();
        invoiceList.push(invoice);
        // TODO handle failure
        invoice.setFields(entry);
        var imageLink = _this._generateDownloadLink(apixclient, 'image', invoice, userData);
        var documentLink = _this._generateDownloadLink(apixclient, 'invoice', invoice, userData);
        invoice.set("imageLink", imageLink);
        invoice.set("documentLink", documentLink);
        // Add observers for statuses that can be changed in the list view.
        invoice.addObserver('invoiceStatus', invoice, function () {
          var storageId = invoice.get('storageID');
          var metadata = invoice.toXML();
          return apixclient.setMetadata(userData.TransferID, storageId, userData.TransferKey, metadata, 'text/xml').then(function (data) {
            _ember['default'].Logger.log("invoice invoiceStatus updated", data);
          });
        });
        invoice.addObserver('paymentStatus', invoice, function () {
          var storageId = invoice.get('storageID');
          var metadata = invoice.toXML();
          return apixclient.setMetadata(userData.TransferID, storageId, userData.TransferKey, metadata, 'text/xml').then(function (data) {
            _ember['default'].Logger.log("invoice paymentStatus updated", data);
          });
        });
        invoice.set('dlURL', apixclient.downloadURL(invoice.get('storageID'), invoice.get('storageKey'), 'no', '', invoice.get('documentID') + '.zip'));
      });
      return [invoiceList, queryLimitExceeded];
    },
    processTrafficData: function processTrafficData(data, apixclient, userData, listcreate, removeCallback, sendreceive) {
      var _this2 = this;

      var invoiceList = [];
      var queryLimitExceeded = false;
      // The query only returns 100 elements. If more present, first element
      // will be 'too long' warning.
      if (data.data[0] && data.data[0].Warning == "Search return list too long") {
        queryLimitExceeded = true;
        data.data.shift();
      }

      var signomDocumentID = null;

      data.data.forEach(function (entry) {
        //Ember.Logger.log(entry);
        var id = entry.StorageID;

        entry.hasPaymentHistoryRecord = null;

        if (entry.signomDocumentID) {
          signomDocumentID = entry.signomDocumentID;
        } else if (signomDocumentID) {
          entry.signomDocumentID = signomDocumentID;
        }

        //let invoice = Traffic.create();
        var invoice = listcreate();
        // TODO handle failure
        invoice.setFields(entry);
        /*
                let bid = entry.receiverId;
                if(sendreceive === "received"){
                  bid = entry.senderId;
                }
        
                apixclient.TrafficLightsGetBusinessId(userData, bid).then((data)=>{
                  console.log(["TrafficLightsGetBusinessId",sendreceive,bid,data]);
                  if(data){
                    invoice.set("hasPaymentHistoryRecord",data.found);
                  }
                }).catch((err)=>{
                  Ember.Logger.error(err);
                  Ember.Logger.error("TrafficLightsGetBusinessId request failed");
                });
        /**/

        var imageLink = _this2._generateDownloadLink(apixclient, 'image', invoice, userData);
        var documentLink = _this2._generateDownloadLink(apixclient, 'invoice', invoice, userData);

        if (entry.uniqueMessageID) {
          imageLink = _this2._generatePitStopDownloadLink(apixclient, 'image', invoice, userData);
          documentLink = _this2._generatePitStopDownloadLink(apixclient, 'data', invoice, userData);
          _ember['default'].Logger.log(["PitStopDownloadLinks", imageLink, documentLink]);
        }

        invoice.set("imageLink", imageLink);
        invoice.set("documentLink", documentLink);
        invoice.set("autoRemove", removeCallback);
        // Add observers for statuses that can be changed in the list view.
        invoice.addObserver('invoiceStatus', invoice, function () {
          var storageId = invoice.get('storageID');
          var metadata = invoice.toXML();
          return apixclient.setMetadata(userData.TransferID, storageId, userData.TransferKey, metadata, 'text/xml').then(function (data) {
            _ember['default'].Logger.log("invoice invoiceStatus updated", data);
          });
        });
        invoice.addObserver('paymentStatus', invoice, function () {
          var storageId = invoice.get('storageID');
          var metadata = invoice.toXML();
          return apixclient.setMetadata(userData.TransferID, storageId, userData.TransferKey, metadata, 'text/xml').then(function (data) {
            _ember['default'].Logger.log("invoice paymentStatus updated", data);
          });
        });
        invoice.set('dlURL', apixclient.downloadURL(invoice.get('storageID'), invoice.get('storageKey'), 'no', '', invoice.get('documentID') + '.zip'));

        if (invoice.uniqueMessageId || invoice.uniqueMessageID) {

          if (invoice.status !== "stopped" && invoice.status !== "redone") {
            invoiceList.pushObject(invoice);
          } else {
            _ember['default'].Logger.log(["passed", invoice]);
          }
        }
      });
      return invoiceList;
    },

    _generateDownloadLink: function _generateDownloadLink(apixclient, role, invoice, userData) {
      var storageId = invoice.get('storageID');

      return apixclient.generateDownloadURL(userData.UniqueCompanyID, userData.TransferID, userData.TransferKey, storageId, role);
    },

    _generatePitStopDownloadLink: function _generatePitStopDownloadLink(apixclient, type, invoice, userData) {
      var uniqueMessageID = invoice.get('uniqueMessageID');

      return apixclient.generatePitStopDownloadURL(userData.UniqueCompanyID, userData.TransferID, userData.TransferKey, uniqueMessageID, "seller", type);
    },

    dynamicSort: function dynamicSort(property, direction, numeric) {
      var sortOrder = 1;
      if (direction) {
        sortOrder = -1;
      }
      var sortHelper = function sortHelper(value) {
        if (numeric) {
          return parseFloat(value);
        }

        if (typeof value === 'string' || value instanceof String) {
          return value.toUpperCase();
        }

        return value;
      };

      return function (a, b) {
        var result = sortHelper(a[property]) < sortHelper(b[property]) ? -1 : sortHelper(a[property]) > sortHelper(b[property]) ? 1 : 0;
        return result * sortOrder;
      };
    },
    formatDate: function formatDate(date) {
      _ember['default'].Logger.log(date);
      _ember['default'].Logger.log(typeof date);
      if (!date) {
        return '';
      }
      var value = date.getFullYear();
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      value = value + '' + month;
      var day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      value = value + '' + day;
      return value;
    }
  };
});